/*
*
* ==========================================
* GENERAL
* ==========================================
*
*/

$sidebar-width: 18rem;
$sidebar-social-width: 4rem;

.text-small {
    font-size: 0.9rem !important;
}

body {
    overflow-x: hidden;
}

.page-holder {
    width: calc(100% - $sidebar-width);
    margin-left: $sidebar-width;
    min-height: 100vh;
    transition: all 0.3s linear;

    @include media-breakpoint-down(md) {
        width: 100%;
        margin-left: 0;
    }

    &.active {
        width: calc(100% - $sidebar-width);
        margin-left: $sidebar-width;
    }
}



/*
*
* ==========================================
* SIDEBAR
* ==========================================
*
*/

.sidebar {
    width: $sidebar-width;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.3s linear;

    &-inner {
        width: 100%;
        height: 100%;
        background: white;
    }

    @include media-breakpoint-down(md) {
        margin-left: -$sidebar-width;
    }

    &.active {
        margin-left: 0;
    }
}

.sidebar-link {
    padding: 0.7rem 1.5rem 0.2rem;
    color: $body-color;
    border-bottom-right-radius: 0;
    transition: all 0.3s;
    position: relative;

    &.active {
        color: $brand-primary;
    }

    &.active, &.active:hover, &.active:focus {
        text-decoration: none;
    }

    &:hover, &:focus {
        text-decoration: none;
        background: white;
    }
}


.listing-item {
    padding-left: 1rem;

    &-description {
        color: #999;
    }
    &-heading {
        transition: all 0.3s;
    }

    .listing-tags {
        transform: rotate(-90deg);
        transform-origin: bottom left;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &:hover {
        .listing-img-holder::before {
            border: 10px solid white;
        }
        .listing-item-heading {
            color: $brand-primary;
        }
        .listing-btn {
            transform: none;
            opacity: 1;
        }
    }
}

.listing-img-holder {
    position: relative;
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid white;
        transition: all 0.3s;
    }
    .listing-btn {
        position: absolute;
        bottom: -1rem;
        right: 0.5rem;
        opacity: 0;
        transition: all 0.3s;
        transform: translate(-0.5rem, 0.5rem);
    }
}


.social-share-link {
    border-radius: 30rem;
    background: $light;
    height: 40px;
    padding: 0 16px 0 5px;
    display: block;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9rem;
    i {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: normal;
    }

    &:hover {
        color: #fff;
    }

    &.facebook {
        &:hover {
            background: #3b5999;
            color: #fff;
            i {
                color: #3b5999;
            }
        }
    }
    &.twitter {
        &:hover {
            background: #55acee;
            color: #fff;
            i {
                color: #55acee;
            }
        }
    }
    &.instagram {
        &:hover {
            background:#e4405f;
            color: #fff;
            i {
                color:#e4405f;
            }
        }
    }
}

strong {
    font-weight: $font-weight-bold;
}
